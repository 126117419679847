import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AccountsSetting } from '../../pages/accounts/accounts-setting.model';
import { Auth } from '../models/auth.model';
import { CurrentClientService } from './current-client.service';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private settings: AccountsSetting[];
    private appSettings = {};

    constructor(
        protected http: HttpClient,
        private currentClientService: CurrentClientService
    ) { }

    public get(reference) {
        if (!this._get()) { return; }
        return this.appSettings[reference] !== undefined ? this.appSettings[reference] : null;
    }

    public all() {
        return this.settings;
    }

    public getBool(reference) {
        if (!this._get()) { return; }
        return !!(this.appSettings[reference] !== undefined && this.appSettings[reference] && this.appSettings[reference] !== '0');
    }

    public getString(reference) {
        if (!this._get()) { return; }
        const value = this.appSettings[reference];
        if (value === undefined) {
            return null;
        }
        if(typeof value === 'boolean') {
            return value ? '1' : '0';
        }
        return value?.toString();
    }

    public getDefault(reference, dflt) {
        return this.get(reference) || dflt;
    }

    public getDefaultReport(reference, model) {
        if (model) {
            return model;
        }
        const defaultModel = this.getString(reference);
        return defaultModel ? defaultModel : '1';
    }

    private _get() {
        if (!this.settings) {
            const user = <Auth>JSON.parse(localStorage.getItem('currentUser'));
            if (!user.account.settings) {
                this.getSettings();
                return false;
            }
            this.setSettings(user.account.settings);
        }
        return true;
    }

    public setSettings(settings?: AccountsSetting[]): void {
        if (!this.settings && !settings) {
            return this.getSettings();
        }
        if (settings) {
            this.settings = settings;
        }
        this.settings.forEach(item => {
            item.value = this.stringToNumber(item.value);
        });
        for (const setting of this.settings) {
            this.settings[setting.reference] = this.stringToNumber(setting.value);
            if (!setting.client_id || setting.client_id === this.currentClientService.id) {
                this.appSettings[setting.reference] = setting.value;
                if (setting.reference === 'title') {
                    this.setTitle(setting.value);
                }
                if (setting.reference === 'logo') {
                    this.setLogo(setting.value);
                }
                if (setting.reference === 'logo-header') {
                    this.setLogoHeader(setting.value);
                }
            }
        }
    }

    public getSettings(): void {
        this.http.get<{data: AccountsSetting[]}>(environment.API_URL + 'accounts_settings/client/0/1/1').subscribe(
            (res) => {
                this.settings = res.data;
                this.setSettings();
            });
    }

    public setTitle(title: string): void {
        localStorage.setItem('fcTitle', title);
    }

    public setLogo(url: string): void {
        localStorage.setItem('fcLogo', url);
    }

    public setLogoHeader(url: string): void {
        localStorage.setItem('fcLogoHeader', url);
    }

    private stringToNumber(value) {
        if (typeof value === 'string') {
            const number = parseFloat(value);
            if (!isNaN(number)) {
                if (number === 0) {
                    return false;
                }
                if (number === 1) {
                    return true;
                }
                return number;
            }
        }
        return value;
    }
}
